var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{attrs:{"id":"product-list"}},[_c('product-add-update',{attrs:{"product-type":_vm.productType,"product-item":_vm.productItem},on:{"refetch-data":_vm.fetchProducts,"upload-image":_vm.uploadImage,"save-product-event":_vm.saveProductEvent},model:{value:(_vm.isAddUpdateProductSidebarActive),callback:function ($$v) {_vm.isAddUpdateProductSidebarActive=$$v},expression:"isAddUpdateProductSidebarActive"}}),_c('product-filter',{on:{"filter-data":_vm.filterData},model:{value:(_vm.isFilterProductSidebarActive),callback:function ($$v) {_vm.isFilterProductSidebarActive=$$v},expression:"isFilterProductSidebarActive"}}),_c('Image-upload',{attrs:{"data":_vm.dataUpload,"isUploadImageModul":_vm.modul,"isActive":_vm.image_isActive,"isImageSize":_vm.imageSize},on:{"refetch-data":_vm.fetchProducts,"save-image-event":_vm.saveImageEvent},model:{value:(_vm.isUploadProductImageActive),callback:function ($$v) {_vm.isUploadProductImageActive=$$v},expression:"isUploadProductImageActive"}}),_c('v-snackbars',{attrs:{"objects":_vm.snackbars,"width":"500","top":"","right":"","transition":"slide-y-transition"},on:{"update:objects":function($event){_vm.snackbars=$event}},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var close = ref.close;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return close()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)]}}])}),_c('v-card',{class:_vm.$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg',attrs:{"tile":"","flat":_vm.$vuetify.breakpoint.mdAndDown}},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-toolbar',{attrs:{"color":_vm.$vuetify.breakpoint.mdAndDown ? 'transparent' : 'transparent',"flat":"","height":_vm.$vuetify.breakpoint.smAndDown ? 57 : 87}},[_c('v-toolbar-title',{staticClass:"text-h6 font-weight-medium"},[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCollage)+" ")]),_vm._v(" Ürünler ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"plain":"","text":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.isFilterProductSidebarActive = !_vm.isFilterProductSidebarActive}}},[(_vm.isFilterNumber)?_c('v-badge',{attrs:{"overlap":"","color":"tertiary","dot":"","offset-x":"15","offset-y":"5"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilter)+" ")])],1):_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilter)+" ")]),_vm._v(" Filitre ")],1),_c('v-btn',{class:{
              'bg-gradient-nightDark': !_vm.isDark,
              'bg-gradient-primary': _vm.isDark, // isDark ise farklı bir stil uygula
              'text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 white--text': true
            },attrs:{"depressed":"","x-large":""},on:{"click":function($event){return _vm.addProduct()},"mouseenter":function($event){_vm.activeHover = true},"mouseleave":function($event){_vm.activeHover = false}}},[_c('span',[_vm._v("Ürün Ekle")]),(_vm.activeHover)?_c('span',[_c('img',{attrs:{"src":require("@/assets/images/misc/tukas-yaprak.png"),"width":"20"}})]):_vm._e()])],1):_c('v-expansion-panels',{attrs:{"flat":"","tile":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('div',{staticClass:"text-body-2 font-weight-medium"},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCollage)+" ")]),_vm._v(" Kullanıcılar ")],1)]),_c('v-expansion-panel-content',{staticClass:"pa-0"},[_c('v-divider'),_c('v-list',{staticClass:"pa-0",attrs:{"color":"grey"}},[_c('v-list-item',{on:{"click":function($event){return _vm.addProduct()}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ürün Ekle")])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlusThick))])],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();_vm.isFilterProductSidebarActive = !_vm.isFilterProductSidebarActive}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Filitre")])],1),_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.isFilterNumber ? 'tertiary' : null}},[_vm._v(" "+_vm._s(_vm.icons.mdiFilter)+" ")])],1)],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.productListTable,"options":_vm.options,"items-per-page":15,"fixed-header":"","height":_vm.dataTableHeight,"server-items-length":_vm.totalProductListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.dosya_adi",fn:function(ref){
            var item = ref.item;
return [_c('v-card',{staticClass:"rounded d-flex align-center justify-center",attrs:{"tile":"","width":"40","height":"40","color":item.pictures ? 'white' : 'primary'}},[(item.pictures)?_c('v-img',{attrs:{"height":"30","width":"30","src":'https://portalapi.tukas.com.tr/' + item.pictures.kresim[0].dosya_adi}}):_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiCameraOff)+" ")])],1)]}},{key:"item.aktif",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.aktif === '1' ? 'success' : 'warning',"link":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.aktif === '1' ? 'Aktif' : 'Pasif')+" ")])]}},{key:"item.urun_kodu",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(item.urun_kodu)+" ")])]}},{key:"item.plant",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"d-flex"},_vm._l((_vm.plants(item.plant)),function(plant,i){return _c('v-chip',{key:(i + "-plant"),staticClass:"mr-1",attrs:{"color":"grey","label":"","small":""}},[_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.statusFind('fabrika', plant, 'text')))])])}),1)]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-r-0 rounded-l-xl",attrs:{"depressed":"","color":"nightDark","x-small":"","to":{ name: 'product-view', params: { data: item } }}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ayrıntılar")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:item.dosya_adi ? 'rounded-l-0 rounded-r-xl' : 'rounded-0',attrs:{"depressed":"","color":"nightDark","x-small":""},on:{"click":function($event){return _vm.editProduct(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiSquareEditOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Düzenle")])]),(item.dosya_adi)?_c('v-tooltip',{attrs:{"bottom":"","color":"warning"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-l-0 rounded-r-xl",attrs:{"depressed":"","color":"warning","x-small":"","plain":""},on:{"click":function($event){return _vm.productImageDelete(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCameraOff)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Resimi Kaldır")])]):_c('v-tooltip',{attrs:{"bottom":"","color":"secondary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-l-0 rounded-r-xl",attrs:{"depressed":"","color":"nightDark","x-small":""},on:{"click":function($event){return _vm.productImageUpload(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCamera)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Resim Yükle")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","color":"error","x-small":"","plain":""},on:{"click":function($event){return _vm.productDelete(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Ürünü Sil")])])]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }